import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import AuthApis from "../APIs/AuthApis";
import WebContext from "../../../application/contexts/WebContext";
import Notifier from "../../../application/utils/Notifier";

import { ReactComponent as AssetLoader } from "./../../../assets/images/loader.svg";
import { ReactComponent as AssetSuccess } from "./../../../assets/icons/success-check.svg";
import assetLoginBanner from "./../../../assets/can-images/login-banner.jpg";
import assetLogo from "./../../../assets/can-images/canada-courier-systems.png";

const Verification = () => {
  const webCtx = useContext(WebContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [isWaiting, setIsWaiting] = useState(true);
  // const { t } = useTranslation();

  useEffect(() => {
    if (webCtx.isLoggedIn) {
      navigate("/home");
    }

    if (searchParams.get("token")) {
      handleVerification(searchParams.get("token"));
    } else {
      navigate("/login");
    }
  }, []);

  const handleVerification = (token) => {
    setIsWaiting(true);
    AuthApis.verifyUser(token)
      .then((res) => {
        setTimeout(() => {
          setIsWaiting(false);
        }, 2000);
      })
      .catch((err) => {
        setTimeout(() => {
          setIsWaiting(false);
          navigate("/login");
          Notifier.notify("error", "Verification code is invalid");
        }, 2000);
      });
  };

  return (
    <div className="container-fluid d-flex flex-column min-vh-100">
      <div className="row flex-grow-1">
        <div
          className="col-lg-5 d-none d-md-block auth-bg p-0"
          style={{ backgroundImage: `url(${assetLoginBanner})` }}
        ></div>

        <div className="col-lg-7 d-flex justify-content-center align-items-center py-4">
          <div className="auth-wrapper w-100">
            <img src={assetLogo} alt="Logo" className="logo img-fluid mb-4" />
            <h3 className="mb-1 mt-3 fw-medium">Verification...!</h3>

            {isWaiting ? (
              <div className="my-4 text-color">
                <AssetLoader className="verify-loader ms-0 mb-2" />
                Your email is being verified, please wait
              </div>
            ) : (
              <div className="my-4 text-color">
                <AssetSuccess className="verify-loader mb-2" />
                <br />
                Thank you for verifying your email. Please proceed to log in.
                <div>
                  <Link to="/login" className="auth-action fw-medium">
                    Login here
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
