import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Importing translation files
import translationEN from "./../../assets/locales/en";
import translationBG from "./../../assets/locales/bg";

let initialLang = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "en";
const resources = {
  en: {
    translation: translationEN,
  },
  bg: {
    translation: translationBG,
  },
};

//i18N Initialization
i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: initialLang,
    fallbackLng: "en", // Default language
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18next;
