import React, { useState, useEffect } from "react";

import ClientApis from "../../presentation/DriverPortal/APIs/ClientApis";
import Global from "../../infrastructure/Common/Global";

const WebContext = React.createContext({
  appTheme: "",
  switchTheme: () => {},

  appLang: "",
  switchLang: () => {},

  isLoggedIn: false,
  changeLoginStatus: () => {},

  isAdmin: () => {},
  isDriver: () => {},

  loginUserInfo: "",
  setLoginUserInfo: () => {},

  appSettings: {},
  setAppSettings: () => {},

  ongoingShiftInfo: {},
  setOngoingShiftInfo: () => {},

  logout: () => {},
});

export const WebContextProvider = (props) => {
  // const getBrowserTheme = () => {
  //     return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark-theme' : 'light-theme';
  // };

  const initialTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light-theme";
  const [theme, setTheme] = useState(initialTheme);

  const initialLang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "en";
  const [language, setLanguage] = useState(initialLang);

  const [settings, setSettings] = useState({});
  const [ongoingShiftInfo, setOngoingShiftInfo] = useState({});

  const siteUrls = {
    login: Global.login,
  };

  useEffect(() => {
    console.log("URL", window.location.pathname);
    if (
      Object.keys(contextValue.ongoingShiftInfo).length === 0 &&
      !window.location.pathname.includes("admin-portal")
    ) {
      console.log("ONGOING CALLED....");
      getShiftInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const themeHandler = (th) => {
    setTheme(th);
    localStorage.setItem("theme", th);
  };

  const languageHandler = (lng) => {
    setLanguage(lng);
    localStorage.setItem("language", lng);
  };

  const isValid = () => {
    const token = localStorage.getItem("auth_token");
    // return true;
    if (token) {
      Global.httpOptions = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const payload = token.split(".")[1];
      const payloadParts = JSON.parse(atob(payload));
      // const payloadParts = JSON.parse(Buffer.from(payload, 'base64'));
      // console.log(payloadParts);
      if (payloadParts) {
        return Object.values(siteUrls).indexOf(payloadParts.iss) > -1
          ? true
          : false;
      }
      // return true;
    } else {
      Global.httpOptions = {};
      return false;
    }

    // setIsUserLogin(st);
    // localStorage.setItem('theme', st);
  };

  const isUserAdmin = () => {
    const token = localStorage.getItem("auth_token");
    if (token) {
      const payload = token.split(".")[1];
      const payloadParts = JSON.parse(atob(payload));
      return payloadParts.access_type === "admin";
    } else return false;
  };

  const isUserDriver = () => {
    const token = localStorage.getItem("auth_token");
    if (token) {
      const payload = token.split(".")[1];
      const payloadParts = JSON.parse(atob(payload));
      return payloadParts.access_type === "driver";
    } else return false;
  };

  const initialUserInfo = localStorage.getItem("user_info");
  const [isUserLogin, setIsUserLogin] = useState(isValid);
  const [userInfo, setUserInfo] = useState(JSON.parse(initialUserInfo));

  const changeAuthStatus = (st) => {
    console.log("CHANGE-State", st);
    setIsUserLogin(isValid);
  };

  const getShiftInfo = () => {
    ClientApis.ongoingShift()
      .then((res) => {
        console.log("SHIFT_INFO", res.data);
        setOngoingShiftInfo(res.data);
      })
      .catch((err) => {});
  };

  const handleLogout = () => {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("user_info");
    // localStorage.removeItem('user');
    setIsUserLogin(isValid);
  };

  const contextValue = {
    appTheme: theme,
    switchTheme: themeHandler,

    appLang: language,
    switchLang: languageHandler,

    isLoggedIn: isUserLogin,
    changeLoginStatus: changeAuthStatus,

    isAdmin: isUserAdmin,
    isDriver: isUserDriver,

    loginUserInfo: userInfo,
    setLoginUserInfo: setUserInfo,

    appSettings: settings,
    setAppSettings: setSettings,

    ongoingShiftInfo: ongoingShiftInfo,
    setOngoingShiftInfo: setOngoingShiftInfo,

    logout: handleLogout,
  };

  return (
    <WebContext.Provider value={contextValue}>
      {props.children}
    </WebContext.Provider>
  );
};

export default WebContext;
