import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import AuthApis from "../APIs/AuthApis";
import Notifier from "../../../application/utils/Notifier";

import assetLoginBanner from "./../../../assets/can-images/login-banner.jpg";
import assetLogo from "./../../../assets/can-images/canada-courier-systems.png";

const SignUp = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [isWaiting, setIsWaiting] = useState(false);
  const { t } = useTranslation();

  const handleSignup = (data) => {
    setIsWaiting(true);
    const formData = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      password: data.password,
      password_confirmation: data.password,
    };

    AuthApis.register(formData)
      .then((res) => {
        setIsWaiting(false);
        reset();
        Notifier.notify("success", res.data.message);
      })
      .catch((err) => {
        // console.log(err);
        setIsWaiting(false);
        Notifier.notify("error", "Email and/or Password is incorrect");
      });
  };

  return (
    <div className="container-fluid d-flex flex-column min-vh-100">
      <div className="row flex-grow-1">
        <div
          className="col-lg-5 d-none d-md-block auth-bg p-0"
          style={{ backgroundImage: `url(${assetLoginBanner})` }}
        ></div>

        <div className="col-lg-7 d-flex justify-content-center align-items-center py-4">
          <div className="auth-wrapper">
            <img src={assetLogo} alt="Logo" className="logo img-fluid mb-4" />
            <h3 className="mb-1 mt-3 fw-medium">Good to see you!</h3>
            <p className="text-color">
              Join now to access exciting platform benefits. Let's get started!
            </p>

            <hr className="my-3" />

            <form onSubmit={handleSubmit(handleSignup)}>
              <div className="row g-2">
                <div className="col-6">
                  <div className="mb-4">
                    <label htmlFor="email" className="form-label">
                      First Name:
                    </label>
                    <input
                      type="text"
                      {...register("first_name", { required: true })}
                      className={`form-control imparo-input-field ${errors.first_name ? "border-danger" : ""}`}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-4">
                    <label htmlFor="email" className="form-label">
                      Last Name:
                    </label>
                    <input
                      type="text"
                      {...register("last_name", { required: true })}
                      className={`form-control imparo-input-field ${errors.last_name ? "border-danger" : ""}`}
                    />
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <label htmlFor="email" className="form-label">
                  Email:
                </label>
                <input
                  type="text"
                  {...register("email", { required: true })}
                  className={`form-control imparo-input-field ${errors.username ? "border-danger" : ""}`}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="password" className="form-label">
                  Password:
                </label>
                <input
                  type="password"
                  {...register("password", { required: true, minLength: 3 })}
                  className={`form-control imparo-input-field ${errors.password ? "border-danger" : ""}`}
                />
              </div>

              <button
                type="submit"
                className="btn nodis-btn primary btn-block w-100 rounded-2 py-2"
                disabled={isWaiting}
              >
                {isWaiting && (
                  <div className="button-loader rounded-2">
                    {t("loading")}...
                  </div>
                )}
                Sign Up
              </button>
              {/* Login Response Error */}
              {/* {error && <p className="text-danger small">{error.detail}</p>}
                            <button type="submit" className="btn btn-primary w-100 mt-2">
                                {isLoading ? isBtnLoader() : <>Login</>}
                            </button> */}
            </form>

            <div className="row mt-3 mb-3">
              <div className="col-md-12">
                <div className="dont-have-account">
                  Already have an account?&nbsp;
                  <Link to="/login" className="auth-action fw-medium">
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
