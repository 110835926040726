import axios from "axios";
import Global from "../../../infrastructure/Common/Global";

class AuthApis {
  login(data) {
    return axios.post(Global.login, data);
  }

  register(data) {
    return axios.post(Global.register, data);
  }

  verifyUser(token) {
    return axios.post(Global.verifyEmail, { verification_token: token });
  }
}

// const authApisInstance = new AuthApis();
export default new AuthApis();
// export default AuthApis;
