import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import WebContext from "../../application/contexts/WebContext";
import Login from "./Components/Login";
import SignUp from "./Components/SignUp";
import Verification from "./Components/Verification";

import "./auth.scss";

const Auth = () => {
  const webCtx = useContext(WebContext);
  const navigate = useNavigate();
  const route = useLocation();
  const url = route.pathname;

  let [formType, setFormType] = useState("");

  useEffect(() => {
    if (webCtx.isLoggedIn) {
      navigate("/");
    }

    console.log("PATH:", url);
    if (url.includes("login")) {
      setFormType("login");
    } else if (url.includes("register")) {
      setFormType("register");
    } else if (url.includes("verify-user")) {
      setFormType("verification");
    } else if (url.includes("forgot-password")) {
      setFormType("forgot");
    } else if (url.includes("reset-password")) {
      setFormType("reset");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const renderForm = (type) => {
    switch (type) {
      case "login":
        return <Login />;
      case "register":
        return <SignUp />;
      case "verification":
        return <Verification />;
      default:
        break;
    }
  };

  return (
    <div className="vh-100 d-flex flex-column justify-content-start align-items-center">
      {renderForm(formType)}
    </div>
  );
};

export default Auth;
