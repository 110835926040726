import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { WebContextProvider } from "./application/contexts/WebContext";

import reportWebVitals from "./reportWebVitals";

// import './Modules/FrontEnd/Shared/i18n';
import "./application/utils/i18n.js";
import "./assets/styles/index.scss";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <WebContextProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </WebContextProvider>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
